<template>
  <div>
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div style="display: flex">
      <el-select v-model="searchType" placeholder="请选择查询类别">
        <el-option label="手机号" value="phone"></el-option>
        <el-option label="身份证号" value="idCard"></el-option>
      </el-select>
      <el-input
        placeholder="请选择类别"
        :disabled="true"
        v-if="this.searchType == ''"
      >
      </el-input>
      <div v-else-if="this.searchType == 'phone'">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="phone"
          width="200"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <el-input
        v-model="idCard"
        width="200"
        v-else-if="this.searchType == 'idCard'"
        placeholder="请输入身份证号"
      ></el-input>
      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="phone" label="手机号" width="130">
        </el-table-column>
        <el-table-column prop="realName" label="真实姓名"> </el-table-column>
        <el-table-column prop="idCard" label="身份证号" width="270">
        </el-table-column>
        <el-table-column prop="faceUrl" label="原始实名照片" width="200">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.faceUrl"
              :preview-src-list="[scope.row.faceUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="carUrl" label="人车合照" width="200">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.carUrl"
              :preview-src-list="[scope.row.carUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100"
          v-if="hasFaceUrl"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleClick(scope.row)"
              >清空刷脸存档</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "车主审核",
        },
        {
          name: "车主刷脸存档",
        },
      ], // 面包屑数据

      valueArea: "",
      options: [],

      tableData: [],

      idCard: "",
      phone: "",
      searchType: "",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");
  },

  computed: {
    hasFaceUrl() {
      return this.tableData.some((row) => row.faceUrl && row.faceUrl !== "");
    },
  },

  methods: {
    searchList() {
      if (this.phone || this.idCard) {
        showLoading();
        const opt = {
          url: reqUrl.getDriverfindDriverFaceInfoUrl,
          method: "POST",
          params: JSON.stringify({
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            idCard: this.idCard,
          }),
          resFunc: (res) => {
            console.log(res.data);

            if (res.data.code == 0) {
              let arr = [];
              arr.push(res.data.data);
              this.tableData = arr;
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handleClick(row) {
      console.log(row);
      this.$confirm("该操作将清空车主存档，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.driverResetDriverFaceUrl,
            method: "POST",
            params: JSON.stringify({
              userId: row.userId,
              phoneArea: this.valueArea || this.phoneAreaROLE,
            }),
            resFunc: (res) => {
              console.log(res.data);

              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "清空成功!",
                });
                this.refresh();
              }

              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
              hideLoading();
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    reset() {
      if (this.phone || this.idCard || this.searchType) {
        // this.phone = "";
        // this.idCard = "";
        // this.searchType = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  margin-right: 10px;
}
.el-input {
  width: 290px;
  margin-right: 20px;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
